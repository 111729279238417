import React from 'react';
import { getNominatorVars } from '../../../utils/env';
import { formatShortAddress } from '../../../utils/format';
import { NumberText } from '../../main/common';
import { CopyAddress } from '../../main/CopyAddress';
import {
  AddressContent,
  AddressLink,
  StakeBox,
  StakeHeading,
  StakeInfo,
  Tokens,
} from '../../main/MainTabs/OverviewTab/Totals';

export function Totals() {
  const nominatorAddress = getNominatorVars().address;

  return (
    <StakeInfo>
      <StakeBox>
        <StakeHeading>Number of nominators</StakeHeading>
        <Tokens>
          <NumberText>
            <span>Untracked</span>
          </NumberText>
        </Tokens>
      </StakeBox>
      <StakeBox>
        <StakeHeading>Total staked</StakeHeading>
        <Tokens>
          <NumberText>
            <span>Untracked</span>
          </NumberText>
        </Tokens>
      </StakeBox>
      <StakeBox>
        <StakeHeading>Contract address</StakeHeading>
        <AddressContent>
          <AddressLink
            href={`https://etherscan.io/address/${nominatorAddress}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {formatShortAddress(nominatorAddress, 6, 3)}
          </AddressLink>
          <CopyAddress text={nominatorAddress} />
        </AddressContent>
      </StakeBox>
    </StakeInfo>
  );
}
