import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { mainTabs } from '../../../types';
import { NominatorTabs } from '../../nominator/NominatorTabs';
import { getMainRoute, useMainParams } from '../../router/routes';
import { ValidatorTabs } from '../../validator/ValidatorTabs';
import { Switch } from '../Switch';
import { responsive } from '../theme';

const MainTabsWrapper = styled.div({
  display: 'flex',
});

const MainTabsOutline = styled.div(({ theme }) => ({
  background: theme.border.secondary,
  flexGrow: 1,
  height: '1px',
  margin: 'auto 0',
  [responsive.md]: {
    marginRight: '-3rem',
    width: 'calc(100% + 3rem)',
  },
  [responsive.sm]: {
    marginRight: '-6rem',
  },
}));

const MainTabsOutlineResponsive = styled(MainTabsOutline)({
  background: '#D2D2E0',
  flexGrow: 1,
  height: '1px',
  margin: 'auto 0',
  display: 'none',
  [responsive.md]: {
    display: 'block',
    marginLeft: '-3rem',
    width: 'calc(100% + 3rem)',
  },
  [responsive.sm]: {
    marginLeft: '-6rem',
  },
});

export function MainTabs() {
  const { scope, tab } = useMainParams();
  const history = useHistory();
  const currentTabKey = mainTabs.indexOf(tab) > -1 ? mainTabs.indexOf(tab) : 0;

  const handleChangeTab = useCallback(
    (key: number) => {
      history.replace(getMainRoute(scope, mainTabs[key]));
    },
    [history, scope],
  );

  return (
    <>
      <MainTabsWrapper>
        <MainTabsOutlineResponsive />
        <Switch
          edges
          values={['Overview', 'Stake', 'Unstake', 'Claim']}
          disabledValues={['Stake']}
          activeKey={currentTabKey}
          onChange={handleChangeTab}
        />
        <MainTabsOutline />
      </MainTabsWrapper>
      {scope === 'validator' && <ValidatorTabs />}
      {scope === 'nominator' && <NominatorTabs />}
    </>
  );
}
