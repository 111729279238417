import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Scope } from '../../types';
import { getEnvironment } from '../../utils/env';
import { getMainRoute, useMainParams } from '../router/routes';
import { Account } from './Account';
import { LargeHeading, LogoImage, NumberText, ValueHeading, WidthWrapper } from './common';
import { MainTabs } from './MainTabs';
import { responsive } from './theme';
import { TooltipIcon } from './TooltipIcon';

const PageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const RightLeftWrapper = styled.div({
  display: 'flex',
});

const LogoImageStyled = styled(LogoImage)({
  margin: '1.5rem 50px 1.5rem 0',
});

const LineTabs = styled.div({
  display: 'flex',
  marginBottom: '3rem',
});

const LineTab = styled.div<{ $active?: boolean }>(({ theme, $active }) => ({
  borderBottom: $active ? `3px solid ${theme.border.primary}` : `1px solid ${theme.border.secondary}`,
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: $active ? theme.text.primary : theme.text.disabled,
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: '4px',
  textTransform: 'uppercase',
  paddingBottom: '1rem',
  padding: '0 1rem 1rem 1rem',
  textAlign: 'center',
  lineHeight: '18px',
  cursor: 'pointer',
  '& span': {
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: '3px',
    transform: 'translate(10%, -25%)',
  },
  [responsive.sm]: {
    fontSize: '14px',
    '& span': {
      display: 'none',
    },
  },
}));

const AnnualYieldWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '3rem',
});

const AnnualYieldBox = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

const MainSection = styled.section({
  flexGrow: 1,
});

const AccountSection = styled.section(({ theme }) => ({
  padding: '2rem 2rem 3rem 2rem',
  marginTop: '3rem',
  marginLeft: '2rem',
  borderRadius: '10px 13px 10px 10px',
  backgroundColor: theme.background.secondary,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  flexShrink: 0,
  width: '400px',
  minHeight: 'calc(100vh - 200px)',
  [responsive.md]: {
    display: 'none',
  },
}));

const TestingLabel = styled.div({
  background:
    'linear-gradient(90deg, #300754 -76.35%, #631D63 -37.07%, #9C2864 -2.17%, #CE4843 30.9%, #ED7D15 65.8%, #F0C023 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 600,
  fontSize: '18px',
  border: '1px solid #D2D2E0',
  boxSizing: 'border-box',
  borderRadius: '100px',
  padding: '15px 60px',
  letterSpacing: '0.25em',
  textTransform: 'uppercase',
});

const TopRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  margin: '1.5rem 0',
});

const Badge = styled.div<{ $active: boolean }>(({ $active }) => ({
  backgroundColor: $active ? 'rgb(238, 85, 85)' : 'rgba(238, 85, 85, 0.5)',
  color: 'white',
  marginLeft: '10px',
  borderRadius: '9999px',
  padding: '2px 10px',
  fontSize: '11px',
  letterSpacing: 'normal',
  fontWeight: 'normal',
}));

const Warning = styled.div(({ theme }) => ({
  fontSize: '17px',
  lineHeight: '20px',
  padding: '0.75rem 1rem',
  background: theme.background.secondary,
  display: 'flex',
  alignItems: 'center',
  margin: '-1.5625rem auto 1rem 0',
  borderRadius: '10px',
  marginBottom: '1rem',
  '& img': {
    marginRight: '1rem',
  },
  [responsive.md]: {
    fontSize: '14px',
    lineHeight: '18px',
  },
}));

const WarningTitle = styled.div({
  fontWeight: 500,
  textTransform: 'uppercase',
  paddingBottom: '8px',
});

const Link = styled.a(({ theme }) => ({
  color: theme.text.primary,
}));

export function Page() {
  const history = useHistory();
  const { scope, tab } = useMainParams();

  const handleChangeScope = useCallback(
    (newScope: Scope) => {
      history.replace(getMainRoute(newScope, tab));
    },
    [history, tab],
  );

  return (
    <PageWrapper>
      <WidthWrapper>
        <RightLeftWrapper>
          <MainSection>
            <TopRow>
              <LogoImageStyled src="/images/syntropy-logo-white.svg" loading="lazy" alt="" />
              {getEnvironment() !== 'prod' && <TestingLabel>{getEnvironment()} environment</TestingLabel>}
            </TopRow>
            <LineTabs>
              <ScopeTab active={scope === 'validator'} scope="validator" onClick={handleChangeScope}>
                VALIDATOR Staking
                <Badge $active={scope === 'validator'}>DISCONTINUED</Badge>
              </ScopeTab>
              <ScopeTab active={scope === 'nominator'} scope="nominator" onClick={handleChangeScope}>
                NOMINATOR Staking
                <Badge $active={scope === 'nominator'}>DISCONTINUED</Badge>
              </ScopeTab>
            </LineTabs>
            {scope === 'validator' && (
              <Warning>
                <img src="/images/important.svg" loading="lazy" alt="" />
                <div>
                  <WarningTitle>The NOIA validator staking program on Ethereum has been discontinued</WarningTitle>
                  <div>
                    The NOIA validator staking program has been discontinued as Synternet has launched its{' '}
                    <Link
                      href="https://www.synternet.com/post/synternet-mainnet-is-now-live"
                      target="_blank"
                      rel="noreferrer"
                    >
                      native blockchain on Cosmos
                    </Link>
                    . We encourage all ex-NOIA validator program participants to{' '}
                    <Link href="https://www.synternet.com/post/synt-token-migration" target="_blank" rel="noreferrer">
                      migrate to SYNT
                    </Link>{' '}
                    and follow Synternet closely for future opportunities to take part as a validator on the new
                    Synternet Blockchain. The validator contract is deployed and remains on the Ethereum mainnet,
                    meaning that your tokens will remain secure until you decide to withdraw them.
                  </div>
                </div>
              </Warning>
            )}
            {scope === 'nominator' && (
              <Warning>
                <img src="/images/important.svg" loading="lazy" alt="" />
                <div>
                  <WarningTitle>Important nominator staking program changes for NOIA stakers</WarningTitle>
                  <div>
                    The NOIA nominator staking program on Ethereum has ended on July 31st, 2024. We encourage all
                    previous NOIA staking participants to migrate and stake SYNT on Synternet’s native blockchain. Find
                    the new staking program{' '}
                    <Link href="https://staking.synternet.com/" target="_blank" rel="noreferrer">
                      here
                    </Link>{' '}
                    and all the relevant information on what changes, how to migrate to SYNT and stake{' '}
                    <Link
                      href="https://www.synternet.com/post/synt-token-utility-is-live"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </Link>
                    {', '}
                    and the updated{' '}
                    <Link href="https://staking.synternet.com/faq/" target="_blank" rel="noreferrer">
                      FAQ
                    </Link>
                    .
                  </div>
                </div>
              </Warning>
            )}
            <AnualYield />
            <MainTabs />
          </MainSection>
          <AccountSection>
            <Account />
          </AccountSection>
        </RightLeftWrapper>
      </WidthWrapper>
    </PageWrapper>
  );
}

interface ScopeTabProps {
  scope: Scope;
  active: boolean;
  onClick: (scope: Scope) => void;
  children: React.ReactNode;
}

function ScopeTab(props: ScopeTabProps) {
  const { scope, active, children, onClick } = props;

  const handleClick = useCallback(() => {
    onClick(scope);
  }, [onClick, scope]);

  return (
    <LineTab $active={active} onClick={handleClick}>
      {children}
    </LineTab>
  );
}

function AnualYield() {
  const { scope } = useMainParams();
  const title = scope === 'validator' ? 'Validators' : 'Nominators';

  return (
    <AnnualYieldWrapper>
      <LargeHeading>NOIA {title}</LargeHeading>
      <AnnualYieldBox>
        <NumberText>0%</NumberText>
        <ValueHeading>APY</ValueHeading>
        <TooltipIcon
          text="Current annual percentage yield as of this moment. The rate decreases as program capacity fills."
          tooltipWidth="400px"
        />
      </AnnualYieldBox>
    </AnnualYieldWrapper>
  );
}
